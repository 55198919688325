








import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProductData, Variant } from '../../../../../contexts'

import {
  Action as CartItemActions,
  CartItemProps
} from '../../../../checkout/molecules/CartItem/CartItem.contracts'
import { CartItem } from '../../../../checkout/molecules/CartItem'
import {
  translateToCartItem,
  translateToCartItemEnhanced
} from '../../../../checkout/molecules/CartItem/CartItem.helpers'
import { CartItemAction } from '../../../../checkout/molecules/CartItem/CartItem.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'SearchResultProductDesktop', components: { CartItem } })
export class SearchResultProductDesktop extends Vue {
  @Prop({ type: Function, required: false })
  public onAddToCart?: (product: Variant<string>) => Promise<void>

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData

  @Prop({ type: Boolean, required: true })
  public useEnhancedTranslator!: boolean

  /**
   * Determines actions of cart item.
   */
  public get actions (): CartItemActions[] {
    if (typeof this.onAddToCart !== 'function') {
      return []
    }

    return [
      {
        label:
          this.$t('front.shared.searchResults.actions.addToCart').toString(),
        key: CartItemAction.AddToCart,
        icon: {
          color: '#5F5F5F',
          height: '1em',
          name: 'ShoppingCartIcon',
          width: '1em'
        },
        onClick: () => typeof this.onAddToCart === 'undefined' ? Promise.resolve()
          : this.onAddToCart(this.product.variants[0])
      }
    ]
  }

  public get toCartItemProps (): CartItemProps {
    if (!this.useEnhancedTranslator) {
      return translateToCartItem(this.product, ['noQuantity'], 100)
    } else {
      return translateToCartItemEnhanced(this.product, ['noQuantity'], 100)
    }
  }

  public onIsLoadingHandler (value: boolean): void {
    this.$emit('isLoading', value)
  }
}
export default SearchResultProductDesktop
