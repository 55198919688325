import { Injectable } from '@movecloser/front-core'
import Inputmask from 'inputmask'
import { IInputMaskService, MaskRules } from './contracts'
import { InputField, inputValidationMaskRules } from './config'

/**
 * Service capable of masking input values
 * @see https://robinherbots.github.io/Inputmask/#/documentation
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Injectable()
export class InputMaskService implements IInputMaskService {
  private maskRules: MaskRules = inputValidationMaskRules
  private options = { noValuePatching: true, inputmode: 'numeric' }

  public formatInputValue (countryCode: string, field: InputField, value: string): string {
    if (!this.maskRules[countryCode]) {
      return value
    }

    const localRule = this.maskRules[countryCode][field]

    return localRule ? Inputmask.format(value, { mask: localRule }) : value
  }

  public maskInputValue (countryCode: string, field: InputField, selector: HTMLElement): void {
    if (typeof window === 'undefined') {
      return
    }

    if (!this.maskRules[countryCode]) {
      return
    }

    const localRule = this.maskRules[countryCode][field]

    const InputMask = localRule ? new Inputmask(localRule, this.options) : new Inputmask(this.options)

    InputMask.mask(selector)
  }
}
