import { AbstractEvent } from '@kluseg/trackify/dist/main'
import { CustomAnalyticsEvent } from '@kluseg/trackify/dist/interfaces/analytics-event'

import { DynamicContentConfig } from '../contracts'

export enum DynamicContentEventName {
  Click = 'click',
  Scroll = 'scroll'
}

export enum DynamicContentAction {
  Click = 'dynamicContent.click',
  Show = 'dynamicContent.show'
}

export enum DynamicContentLabel {
  Click = 'Kliknięto w rekomendację',
  Show = 'Wyświetlono rekomendację'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export class DynamicContent extends AbstractEvent implements CustomAnalyticsEvent<DynamicContentConfig> {
  name = 'custom.dynamicContentClick'

  constructor (protected config: DynamicContentConfig, protected eventName: string, protected action: string) {
    super(config)
    this.eventName = eventName
    this.action = action
  }

  forDriver (driverName: string) {
    if (driverName === 'SyneriseBrowserDriver') {
      return {
        event_name: this.eventName,
        event_payload: this.getData(),
        event_type: this.action,
        event_mapping: {
          action: 'action',
          eventUUID: 'eventUUID',
          unique: 'unique',
          createDate: 'createDate',
          title: 'title',
          url: 'url',
          recoId: 'reco_id',
          source: 'source'
        }
      }
    }
    return null
  }

  getData (): DynamicContentConfig {
    return { ...this.config }
  }
}
