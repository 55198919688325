// Copyright © 2021 Move Closer

import { ApiConnectorType, ConnectorMiddleware, Interfaces, ProvidersFactory, WindowType } from '@movecloser/front-core'

import { ISiteService, SiteService, SiteServiceType } from '@core'

import {
  IdentityMiddleware,
  IdentityMiddlewareType,
  IdentityService,
  IdentityServiceType,
  IIdentityService
} from '@service/identity'
import { ISetupService, SetupService, SetupServiceType } from '@service/setup'
import { SiteMiddleware, SiteMiddlewareType } from '@service/site'
import { shareServiceConfig } from '@config/share'
import { IShareService, ShareServiceType } from '@/shared/core/src/front/shared/contracts/share'
import { ShareService } from '@service/share'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const services: ProvidersFactory = () => {
  return (bind: Interfaces.Bind) => {
    bind<ConnectorMiddleware>(IdentityMiddlewareType).toDynamicValue(
      context => new IdentityMiddleware(context.container.get(IdentityServiceType))
    ).inSingletonScope()

    bind<ConnectorMiddleware>(SiteMiddlewareType).toDynamicValue(
      context => new SiteMiddleware(context.container.get(SiteServiceType))
    ).inSingletonScope()

    bind<IIdentityService>(IdentityServiceType).to(IdentityService).inSingletonScope()

    bind<ISetupService>(SetupServiceType).toDynamicValue(
      context => new SetupService(
        context.container.get(ApiConnectorType),
        context.container.get(IdentityServiceType),
        context.container.get(SiteServiceType)
      )
    ).inSingletonScope()

    bind<ISiteService>(SiteServiceType).to(SiteService).inSingletonScope()

    bind<IShareService>(ShareServiceType).toDynamicValue(
      context => new ShareService(
        shareServiceConfig,
        context.container.get(WindowType)
      )
    ).inSingletonScope()
  }
}
