import { Injectable } from '@movecloser/front-core'

import { IToastsService, ToastType } from './toasts.contracts'
import { IToastOptions } from '../../../../backoffice/shared/services/toaster'
import { Notyf } from 'notyf'

/**
 *
 * **Toast Service**
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Injectable()
export class ToastsService implements IToastsService {
  private readonly config: IToastOptions
  private readonly notify: Notyf
  private readonly defaultOptions: IToastOptions = {
    dismissible: false,
    duration: 1000,
    position: {
      x: 'right',
      y: 'top'
    },
    ripple: false,
    types: [
      {
        type: 'success',
        background: '#28A745',
        icon: {
          className: 'material-icons',
          tagName: 'i',
          text: 'warning'
        }
      },
      {
        type: 'warning',
        background: '#FFC107',
        icon: {
          className: 'material-icons',
          tagName: 'i',
          text: 'warning'
        }
      },
      {
        type: 'info',
        background: '#007BFF',
        icon: {
          className: 'material-icons',
          tagName: 'i',
          text: 'warning'
        }
      },
      {
        type: 'danger',
        background: '#DC3545',
        duration: 2000,
        dismissible: true
      }
    ]
  }

  constructor (config: Partial<IToastOptions>) {
    const options: IToastOptions = {
      ...this.defaultOptions,
      ...config
    }
    this.config = options
    this.notify = new Notyf(options)
  }

  public getOptionsConfig (): IToastOptions {
    return this.config
  }

  /**
   * Display toast with specified type.
   */
  public show (content: string, type = ToastType.Info, title = '', options?: IToastOptions): void {
    const candidateOptions = this.composeOptions(options)

    this.notify.open({
      type: type,
      message: (title) ? `${title}: ${content}` : content,
      ...candidateOptions
    })
  }

  /**
   * Compose options by merging given with default.
   */
  protected composeOptions (options?: IToastOptions): IToastOptions {
    if (!options) {
      return this.config
    }

    return { ...this.config, ...options }
  }
}
