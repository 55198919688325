import { InputField } from './config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface IInputMaskService {
  formatInputValue (countryCode: string, field: InputField, value: string): string
  maskInputValue (countryCode: string, field: InputField, selector: HTMLElement): void
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export type MaskRule = Record<string, string>

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export type MaskRules = Record<string, MaskRule>

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const InputMaskServiceKey = Symbol.for('IInputMaskService')
