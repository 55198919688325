// Copyright © 2021 Move Closer

import { AppModule, Interfaces, Module } from '@movecloser/front-core'

import { ContentRepository } from './repositories'
import { ContentRepositoryType, IContentRepository } from './contracts'
import {
  ISupportFormats,
  SupportedFormatsMiddleware,
  SupportedFormatsMiddlewareType
} from './services'
import { routesFactory as routes } from './routes'
import { storeFactory as state } from './store'
import {
  ISessionService,
  SessionMiddleware,
  SessionMiddlewareType,
  SessionService,
  SessionServiceType
} from '@/shared/core/src/front/auth/services'
import { CacheService, CacheServiceType, ICacheService } from '@/shared/core/src/front/auth/services/cache'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@AppModule({
  name: 'content',
  providers () {
    return (bind: Interfaces.Bind) => {
      bind<IContentRepository>(ContentRepositoryType).to(ContentRepository)
      bind<ISupportFormats>(SupportedFormatsMiddlewareType).to(SupportedFormatsMiddleware).inSingletonScope()
      bind<ISessionService>(SessionServiceType).to(SessionService).inSingletonScope()
      bind<ICacheService>(CacheServiceType).to(CacheService).inSingletonScope()
      bind(SessionMiddlewareType)
        .toDynamicValue(context => new SessionMiddleware(context.container.get(SessionServiceType)))
        .inSingletonScope()
    }
  },
  routes,
  state
})
export class ContentModule extends Module {}
