
import { CheckoutModuleConfig } from '@/shared/core/src/front/checkout/services/checkout'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_CONFIG_KEY = 'checkout'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const checkoutConfig: CheckoutModuleConfig = {
  hasBundledProducts: true
}
