import { AppModule, Container, IConfiguration, Interfaces, Module } from '@movecloser/front-core'
// import { configuration } from '../../../../../config/components'

import { COMPONENTS_STRUCTURE_CONFIG, logger } from '../../support'
import { GraphQlConnectorType, IGraphQL } from '../../contexts'

import { IProfileTabsService, ProfileTabsServiceType } from '../profile/services/profile-tabs'
import { pickTabItemsFactory } from '../shared/support/tabs.factory'

import { loyaltyQueries } from './config/queries'
import { routesFactory as routes } from './routes'
import { LoyaltyService } from './services/loyalty'
import { ILoyaltyService, LoyaltyServiceType } from './contracts/services'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@AppModule({
  name: 'loyalty',
  providers: () => (bind: Interfaces.Bind) => {
    bind<ILoyaltyService>(LoyaltyServiceType).toDynamicValue((context) => {
      return new LoyaltyService(context.container.get(GraphQlConnectorType))
    }).inSingletonScope()
  },
  boot (container: Container, config: IConfiguration) {
    const graph = container.get<IGraphQL>(GraphQlConnectorType)
    graph.useQueries(loyaltyQueries)

    // Create profile tabs
    const profileTabsService = container.get<IProfileTabsService | undefined>(ProfileTabsServiceType)
    if (profileTabsService) {
      /**
       * TODO: Replace 'configuration' with config provided by container
       */
      profileTabsService.addTab(pickTabItemsFactory(config.byKey(COMPONENTS_STRUCTURE_CONFIG), 'loyalty'))
    } else {
      logger('[LoyaltyModuleModule]: There\'s no ProfileTabsService available', 'debug')
    }
  },
  routes
  // state
})

export class LoyaltyModule extends Module {}
