import { VueConstructor } from 'vue'

export enum BenefitProgram {
  FriendsAndFamily = 'friendsAndFamily',
  Kameleon = 'kameleon',
  MilesAndMore = 'milesAndMore'
}

export interface LinkData {
  label: string
  target: string
  tabTarget: string
  title: string
}

export interface BenefitProgramData {
  benefits: string[] | null
  description: string
  id: BenefitProgram
  logo?: string
  name: string
  link: LinkData | null
  addon?: BenefitProgramAddon
}

export interface BenefitProgramAddon {
  component: VueConstructor
  props: Record<string, unknown>
}
