


import { Component, Vue } from 'vue-property-decorator'

import { AllowedThemeSchema } from '@core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'HasTheme'
})
export class HasTheme extends Vue {
  public get theme (): string {
    const theme = this.$store.getters['content/theme']

    if (theme === null || theme === AllowedThemeSchema.Default) {
      return ''
    }

    return `--themes-${theme}`
  }
}

export default HasTheme
