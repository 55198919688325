// Copyright © 2022 Move Closer

import VueI18n from 'vue-i18n'
import VueRouter, { RouteConfig } from 'vue-router'
import { Bootstrapper, Container, IConfiguration, ResourceActionFailed, StoreStack } from '@movecloser/front-core'
import { ModuleTree } from 'vuex'

import { applyPatches } from '@/bootstrap/patcher'
import { config } from '@config/index'
import { getEntryPointFromContext } from '@support/entry-point'
import { ISetupService, SetupServiceType } from '@service/setup'
import { Context, logger } from '@core'
import { State, Store } from '@contract/store'

import { createI18n, FALLBACK_LOCALE } from './i18n'
import { createRouter } from './router'
import { createStore } from './store'
import { decorateStaticRoutes } from './static-routes'
import { ISessionService, loadSessionCookie, SessionServiceType } from '@/shared/core/src/front/auth/services'

export interface BootedApp {
  configuration: IConfiguration
  container: Container
  i18n: VueI18n
  router: VueRouter
  store: Store
}

/**
 * Creates the application instance.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (edited)
 */

export async function createApp (context: Context): Promise<BootedApp> {
  let locale = FALLBACK_LOCALE
  const bootstrapper = new Bootstrapper(config)
  await bootstrapper.boot()

  const configuration: IConfiguration = bootstrapper.getConfiguration()
  const container: Container = bootstrapper.getContainer()

  const storeStack: StoreStack = bootstrapper.getStoreStack()
  const store = createStore(storeStack as ModuleTree<State>)

  if (context.isClient) {
    await applyPatches(container, configuration)
  }

  const session: ISessionService = container.get(SessionServiceType)
  session.setSessionToken(loadSessionCookie(context))

  const setupService: ISetupService = container.get(SetupServiceType)
  const entryPoint = getEntryPointFromContext(context)
  if (entryPoint) {
    try {
      await setupService.initApp(entryPoint, context.isClient)
      await store.dispatch('setMaintenanceMode', setupService.isMaintenanceMode)
      locale = setupService.currentLocale.toLowerCase()
    } catch (error) {
      if (error instanceof ResourceActionFailed && error.status === 503) {
        await store.dispatch('setMaintenanceMode', setupService.isMaintenanceMode)
      } else {
        logger(error, 'error')
        throw error
      }
    }
  }

  const routesStack: RouteConfig[] = decorateStaticRoutes(
    bootstrapper.getRoutesStack() as RouteConfig[],
    setupService.staticRouting
  )
  const router = createRouter(routesStack, container)
  const i18n = await createI18n(locale)

  return { configuration, container, i18n, router, store }
}
