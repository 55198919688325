import { AbstractEvent, SignUpConfig } from '@kluseg/trackify/dist/main'
import { AnalyticsEvent } from '@kluseg/trackify/dist/interfaces/analytics-event'

export class SignUpEvent extends AbstractEvent implements AnalyticsEvent<SignUpConfig> {
  name = 'sign_up'

  constructor (protected config: SignUpConfig) {
    super(config)
  }

  getData (): SignUpConfig {
    return {
      method: this.config.method,
      firstname: this.config.firstname,
      lastname: this.config.lastname,
      email: this.config.email,
      id: this.config.id,
      shop_id: this.config.shop_id,
      emailOffers: this.config.emailOffers,
      receive_smses: this.config.smsOffers
    }
  }
}
