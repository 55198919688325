export enum ShareType {
  CopyLink = 'copy-link',
  Email = 'email',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Twitter = 'twitter'
}

export interface SharePayload {
  url: string
  [key: string]: unknown
}

export interface IShareService {
  share (service: ShareType, payload: SharePayload): void
}

export const ShareServiceType = Symbol.for('IShareService')
