
import { FlightsConfig } from '@/shared/core/src/front/shared/services/flights'

export const FLIGHTS_KEY = 'flights'

export const flights: FlightsConfig = {
  enabledApi: {
    AERO_DATA_BOX: true
  }
}
