/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface Przelewy24TransactionOutput {
  token: string
  transaction: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum CheckoutAgreement {
  EmailPromotion = 'email-promotion',
  SmsPromotion = 'sms-promotion'
}
