export interface CartOptions {
  cartSize: string
  summaryItemWrappers: string[]
  couponButtonTheme: string
  couponButtonVariant: string
}

export const CART_COMPONENT_KEY = 'Cart'

export const CART_BUNDLE_DISCOUNT_PREFIX = 'BUNDLE'

export const FREE_DELIVERY_VALUE = 'freeDeliveryValue'
