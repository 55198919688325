// Copyright © 2022 Move Closer

import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { Container, EventbusType, IEventbus } from '@movecloser/front-core'

import { ISiteService, logger, SiteServiceType } from '@core'

import { isUrlFile } from '@support/is-url-file'

Vue.use(VueRouter)

/**
 * Creates the VueRouter instance with all add-ons.
 *
 * @param routes - Routes configuration from the `@AppModule`s.
 * @param container - IoC container.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const createRouter = (routes: RouteConfig[], container: Container): VueRouter => {
  const siteService: ISiteService = container.get(SiteServiceType)

  const router = new VueRouter({
    base: siteService.getActiveSiteBasePath(),
    mode: 'history',
    routes,

    scrollBehavior (to, from, savedPosition) {
      if (to.hash) {
        const additionalOffset: number = Number(window.document.documentElement.style.getPropertyValue('--body-margin-top')) > 0 ? Number(window.document.documentElement.style.getPropertyValue('--body-margin-top')) : 116
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              selector: to.hash,
              offset: { x: 0, y: additionalOffset }
            })
          }, 1000)
        })
      }

      if (savedPosition) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(savedPosition)
          }, 1000)
        })
      }

      return { x: 0, y: 0 }
    }
  })

  let eventBus: IEventbus
  try {
    eventBus = container.get(EventbusType)
  } catch (error) {
    if (process.env.VUE_APP_ENV === 'local') {
      throw error
    } else {
      logger(error, 'error')
    }
  }

  // Prevent unwanted route changes.
  router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    const shouldAbort: boolean = isUrlFile(to.fullPath)
    return shouldAbort ? next(false) : next()
  })

  // Treat `mailto:` and `tel:` links as external links.
  router.beforeEach((to, from, next) => {
    logger(`[RouterBefore]: Changed from [${from.fullPath}] to [${to.fullPath}]`, 'debug')

    if (typeof window === 'undefined') {
      return next()
    }

    const matches = to.fullPath.match(/(?<type>tel|mailto):(?<value>.*)/g)

    /**
     * Determines whether the link clicked by the User is actually a mail-type or a phone-type link.
     */
    const isMailOrTel: boolean = matches !== null

    if (!isMailOrTel) {
      return next()
    }

    // We can safely use the `as` keyword here, because we performed all the required checks earlier in the code.
    window.location.href = matches?.[0] as string
    return next(false)
  })

  // Emit event on every route change.
  router.afterEach((to: Route, from: Route) => {
    logger(`[RouterAfter]: Changed from [${from.fullPath}] to [${to.fullPath}]`, 'debug')

    if (!eventBus) {
      return
    }

    eventBus.emit('ui:router.changed', { from, to })
  })

  return router
}
