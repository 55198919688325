// Copyright © 2021 Move Closer

/**
 * Supported values for the `[lang]` attribute of the `<html>` tag.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum HtmlLang {
  Pl = 'pl-PL',
  En = 'en-US'
}
