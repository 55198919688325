// Copyright © 2021 Move Closer

import { AnyObject, Injectable, IWindow } from '@movecloser/front-core'

import {
  EmailSharePayload,
  FacebookSharePayload,
  PopUpConfig,
  ShareServiceConfig
} from './share.contracts'
import { openPopUp } from './share.helpers'
import { IShareService, SharePayload, ShareType } from '@/shared/core/src/front/shared/contracts/share'

@Injectable()
export class ShareService implements IShareService {
  private readonly config: ShareServiceConfig
  private readonly windowService: IWindow

  public constructor (config: ShareServiceConfig, windowService: IWindow) {
    this.windowService = windowService
    this.config = config
  }

  public share (service: ShareType, payload: SharePayload): void {
    let emailPayload: EmailSharePayload
    const meta = payload.meta as AnyObject
    switch (service) {
      case ShareType.Email:
        emailPayload = {
          subject: encodeURIComponent(meta.title ?? ''),
          body: encodeURIComponent(`${meta.description}

Przeczytaj więcej pod tym linkiem:

${payload.url}
`
          )
        }
        this.emailShare(emailPayload)
        break

      case ShareType.Facebook:
        this.facebookShare(payload)
        break
    }
  }

  private emailShare (payload: EmailSharePayload): void {
    const url: string = `mailto:?subject=${payload.subject}&body=${payload.body}`
    const config: PopUpConfig = {
      width: '750',
      height: '550'
    }

    openPopUp(url, config, this.windowService)
    // window.location.assign(`mailto:?subject=${payload.subject}&body=${payload.body}`)
  }

  private facebookShare (payload: FacebookSharePayload): void {
    const url: string = `https://www.facebook.com/dialog/share?app_id=${this.config.facebook.appId}&href=${payload.url || ''}&quote=${payload.quote || ''}&hashtag=${payload.hashtag || ''}`

    const config: PopUpConfig = {
      width: '750',
      height: '550'
    }

    openPopUp(url, config, this.windowService)
  }
}
