// Copyright © 2021 Move Closer

import { ContainerProps } from '../../dsl/molecules/Container'
import { Container, ImageData, Related } from '../../contexts'
import { AnyObject } from '@movecloser/front-core'

export {
  ContainerMargin as AllowedContainerMargin,
  ContainerPadding as AllowedContainerPadding,
  ContainerWidth as AllowedContainerWidth
} from '../../dsl/molecules/Container'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum AllowedBackgroundColor {
  black = '#000',
  white = '#fff',
  navy = '#535D79',
  darkNavy = '#444F6C',
  lightBlue = '#EEF2FE',
  blue = '#ABBFF8',
  u20Navy = '#1B2930',
  u20LightGrey = '#F9F9F9',
  lightGrey = '#F6F7FA',
  dirtyPink = '#ED9EB2',
  dirtyLightPink = '#F8C7D1',
  lightPink = '#FE8DB1',
  darkPink = '#C70058',
  red = '#D94041',
  lightRed = '#F7D9D9',
  lightGreen = '#CCE4CD',
  green = '#007A03',
  yellow = '#D8AC00',
  lightYellow = '#F8F3E2',
  None = ''
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum AllowedContainerColumnGap {
  Large = 'large',
  None = 0,
  Unset = '',
}

/**
 * @author @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 */
export enum AllowedContainerZIndex {
  ZIndexN1 = -1,
  None = 0,
  ZIndex1 = 1,
  ZIndex10 = 10,
  Unset = '',
}

/**
 * @author @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum AllowedContainerRowGap {
  None = 0,
  Spacer1 = 1,
  Spacer2 = 2,
  Spacer3 = 3,
  Spacer4 = 4,
  Spacer5 = 5,
  Spacer6 = 6,
  Spacer7 = 7,
  Spacer8 = 8,
  Spacer9 = 9,
  Spacer10 = 10,
  Unset = '',
}

/**
 * @author @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum AllowedImagePosition {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Top = 'top',
  BottomRight = 'bottom right',
  BottomLeft = 'bottom left',
  CenterRight = 'center right',
  TopRight = 'top right',
  TopLeft = 'top left',
  CenterLeft = 'center left',
  CenterTop = 'center top',
  CenterBottom = 'bottom center',
  Fixed = 'fixed'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerBackground {
  color: string
  image: ImageData
  imagePosition: string
  variantColor: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerBackgroundInput {
  color: string
  image: Related
  imagePosition: string
  variantColor: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerContent extends Omit<ContainerProps, 'background' | 'id'> {
  background?: Partial<ContainerBackground>
  gridGap?: ContainerGridGap
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerContentInput extends Omit<ContainerContent, 'background'> {
  background: Partial<ContainerBackgroundInput>
}

export interface ContainerOptions {
  backgroundVariants: string[]
  backgroundColorPalette: AnyObject
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ContainerData = Container & ContainerContentInput

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerGridGap {
  column: string | number
  row: string | number
}
