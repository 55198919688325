
import { IConnector, ResourceActionFailed } from '@movecloser/front-core'

import { AirportScheduleItem } from '../../../../contexts/checkout/contracts/flight-data'
import { ISiteService } from '../../../../contexts'
import { resolveFromStatus } from '../../../../support'

import { FlightsAPI, FlightsConfig, IFlightsService } from './contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export class FlightsService implements IFlightsService {
  protected _connector: IConnector
  protected _config: FlightsConfig
  protected _siteService: ISiteService

  constructor (connector: IConnector, siteService: ISiteService, config: FlightsConfig) {
    this._connector = connector
    this._config = config
    this._siteService = siteService
  }

  public async getAirportsSchedule (date: string): Promise<Array<AirportScheduleItem> | undefined> {
    if (!this._config.enabledApi[FlightsAPI.AeroDataBox]) {
      console.error(`[FlightsService:getAirportSchedule] 'enabledApi' doesn't match required api provider: ${FlightsAPI.AeroDataBox}`)
      return
    }

    const response = await this._connector.call('flights', 'departures', { date }, {})

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.data.message,
        resolveFromStatus(response.status),
        {}
      )
    }

    return response.data.data
  }
}
