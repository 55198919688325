// Copyright © 2021 Move Closer

import { AnalyticsConfig } from '@/shared/core/src/front/analytics/contracts'

/**
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 */
export const analytics: AnalyticsConfig = {
  TRACKIFY_DEBUG: true,
  TRACKIFY_GTM: true,
  TRACKIFY_SYNERISE: true,
  TRACKIFY_USERCOM: false,
  TRACKIFY_TRADEDOUBLER: true,
  TRACKIFY_CENEO: true
}
