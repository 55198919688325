






























import { AnyObject, Container, ResourceActionFailed } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { ISiteService, SiteModel, SiteServiceType } from '@core'

import { AsyncDataContext, ErrorCallback, RedirectCallback } from '@contract/async-data'
import { Store } from '@contract/store'

import { AbstractView } from './AbstractView'
import { automaticRedirection, detectSupportedFormats } from '../helpers'
import { ContentRepositoryType, IContentRepository } from '../contracts'
import { ISupportFormats, SupportedFormatsMiddlewareType } from '../services'

/**
 * **MOST IMPORTANT VIEW AND COMPONENT IN THE WHOLE APP.**
 *
 * ➡️ It's being rendered under the "catch-all" route.
 * ➡️ Before it gets mounted, it tries to fetch the page's data from the API, using the URL
 * entered by the User. If the API call is a success, then the page's content's are rendered
 * using the API's response. Of course, if the API call has failed, we provide the User with the
 * applicable error page.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ContentView>({
  name: 'ContentView',

  beforeRouteUpdate (to, from, next): void {
    this.$store.commit('content/setRoute', from)
    this.$store.commit('content/setReCall', this.considerReCallingForData(to))

    next()
  },

  async asyncData (context: AsyncDataContext): Promise<AnyObject | void> {
    const { url } = context
    if (context.app.$store.getters.isMaintenanceMode) {
      return
    }

    if (!context.app.$store.getters['content/shouldReCall']) {
      context.app.$store.commit('content/setReCall', true)

      return {
        result: context.app.$store.getters['content/response']
      }
    }

    const container = context.app.$container as Container
    const siteService: ISiteService = container.get(SiteServiceType)
    const contentRepository: IContentRepository = container.get(ContentRepositoryType)
    const supportedFormats: ISupportFormats = container.get(SupportedFormatsMiddlewareType)

    if (!context.app.$store.getters['content/hasSupportedFormats']) {
      const supportedFormats = detectSupportedFormats(context)
      context.app.$store.commit('content/addSupportedFormat', supportedFormats)
    }

    if (!supportedFormats.hasSupportedFormats()) {
      supportedFormats.setSupportedFormats(context.app.$store.getters['content/supportedFormats'])
    }

    try {
      const result = await contentRepository.load(
        url.replace(siteService.getActiveSiteBasePath(), ''),
        {},
        false
      )
      context.app.$store.commit('content/setTheme', result.theme)

      if (context.ssr && result.httpCode && result.httpCode === 404) {
        context.ssr!.res.statusCode = 404
      }

      context.app.$store.commit('content/setReCall', true)

      return { result }
    } catch (error: any) {
      context.app.$store.commit('content/clearResponse')

      if (error.status === 404) {
        context.$errorHandler.statusCode = 404
        context.$errorHandler.error = new Error()

        const result = await contentRepository.load(
          'not-found',
          {},
          false
        )

        return { result }
      }

      // Note! This is on purpose. Decorator is execute before actual code so there's ContentView already defined.
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      ContentView.handleInvariants(
        error,
        siteService.getActiveSite(),
        context.error,
        context.app.$store,
        [...context.app.$store.getters.routesHistory].pop() ?? context.app.$route.fullPath,
        context.isServer ? context.app.$redirect : (target: RawLocation) => {
          context.app.$router.push(target)
        }
      )
    }

    return { result: null }
  }
})
export class ContentView extends AbstractView {
  protected allowedQueryParams: string[] = []

  public get contentType (): string {
    if (!this.result) {
      return ''
    }

    return 'ContentView--' + (this.result.content.type || '')
  }

  private static handleInvariants (
    error: ResourceActionFailed,
    site: SiteModel,
    handler: ErrorCallback,
    store: Store,
    route: string,
    redirect: RedirectCallback
  ): void {
    switch (error.status) {
      case 410: {
        redirect(error.payload.redirectTo, error.payload.redirectionCode ?? 303)
        break
      }

      case 503: {
        store.dispatch('setMaintenanceMode', true)
        break
      }

      default: {
        try {
          automaticRedirection(error.status, route, site, redirect)
        } catch {
          let message: string = `[ContentView]: ${error.message}`

          if (
            typeof error.payload === 'object' &&
            error.payload !== null &&
            typeof error.payload.slug !== 'undefined'
          ) {
            message += ` for ${error.payload.slug}`
          }

          handler(message, Number(error.status))
        }
      }
    }
  }
}

export default ContentView
