import { AirportScheduleItem } from '../../../../contexts/checkout/contracts/flight-data'

export const FlightsServiceKey = Symbol.for('IFlightsService')

export interface IFlightsService {
  /**
   * Returns an airport flights schedule for a specific date.
   * @see https://rapidapi.com/aedbx-aedbx/api/aerodatabox (Proxy for GET FIDS/Schedules: Airport departures and arrivals)
   *
   * Proxy rules:
   * List of IATA departure airport codes are predefined.
   * Schedule is retrieved for whole 24 hours for each airport.
   * Proxy: Hubert Smusz <hubert.smusz@movecloser.pl>
   *
   * @param date (date in format YYYY-MM-DD)
   */
  getAirportsSchedule (date: string): Promise<Array<AirportScheduleItem> | undefined>
}

export interface FlightsConfig {
  enabledApi: Record<string, boolean>
}

export enum FlightsAPI {
  AeroDataBox = 'AERO_DATA_BOX'
}
