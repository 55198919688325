// Copyright © 2021 Move Closer

import { Context } from '@core'
import { isUrlFile } from '@support/is-url-file'

/**
 * This method extracts current entry point from context.
 *
 * @param context
 *
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const getEntryPointFromContext = (context: Context): string | null => {
  let entryPoint: string = context.isClient
    ? window.location.href : `${context.ssr?.req.headers.host}${context.url}` || ''

  if (isUrlFile(entryPoint)) {
    return null
  }

  // NOTE! To prevent mismatches due to HTTP protocol, we need to remove it from entryPoint.
  if (entryPoint.startsWith('http')) {
    entryPoint = entryPoint.replace('http://', '')
      .replace('https://', '')
  }

  // NOTE! This override is required for development process when early stage requires `client:ssr`.
  if (
    process.env.VUE_APP_ENV === 'local' &&
    entryPoint.startsWith('localhost') &&
    !!process.env.VUE_APP_SETUP_DOMAIN
  ) {
    entryPoint = [
      process.env.VUE_APP_SETUP_DOMAIN,
      entryPoint
    ].join('.')
  }

  return entryPoint.replace(/^\/|\/$/g, '')
}

/**
 * Determines whether the given URL points to the file, instead of a resource.
 *
 * @param url - The URL to check.
 */
export function isCallingForFile (url: string): boolean {
  const reg = /[^.]*(\.[\w\d]+)*(\.[\w]{1,4})$/g
  const result = reg.exec(url)

  return result !== null || url.endsWith('.webmanifest')
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function preventRedirectionLoop (entry: string, target: string): boolean {
  return entry.replace(/^\/|\/$/g, '') ===
    target.replace('http://', '')
      .replace('https://', '')
      .replace(/^\/|\/$/g, '')
}
