
export const FLIGHTS_KEY = 'flights'

export interface AirportScheduleItem {
  destination: AirportData
  flight: string
  origin: AirportData
  date: string
}

export interface AirportData {
  iata: string
  name: string
  translated: string
}
