// Copyright © 2022 Move Closer

import 'reflect-metadata'
import 'wicg-inert'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import NoSSR from 'vue-no-ssr'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueMeta from 'vue-meta'

import { Context, CustomDSL, FrontCore, registerExtensions } from '@core'
import { BootedApp, createApp } from '@/bootstrap/app'
import { buildDSLRegistry } from '@/shared/core/src/front/shared/icons/icons.helpers'
import { iconsRegistry } from '@/shared/icons'
import { initSentry } from '@support/sentry'
import { isCallingForFile } from '@support/entry-point'
import { WindowService } from '@movecloser/front-core'

import './scss/index.scss'
import './registerSerwiceWorker'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false

Vue.use(CustomDSL, {
  icons: buildDSLRegistry(iconsRegistry),
  prefix: 'Ui'
})
Vue.use(FrontCore)

Vue.use(VueI18n)
Vue.use(VueMeta)

Vue.use(VueLazyload, {
  adapter: {
    loaded ({ el }: { el: HTMLElement }) {
      const srcset = el.getAttribute('data-srcset')
      if (srcset) {
        el.setAttribute('srcset', srcset)
      }
    }
  },
  attempt: 1,
  preLoad: 2.5
})

Vue.component('no-ssr', NoSSR)

registerExtensions(Vue)

export default (context: Context): Promise<Vue> => {
  if (isCallingForFile(context.url)) {
    const message: string = `File not found: ${context.url}`
    if (context.isClient) {
      throw new Error(message)
    }

    context.res?.status(404).send(message)
    return Promise.reject(
      new Error(message)
    )
  }

  return createApp(context).then((bootedApp: BootedApp) => {
    const {
      configuration,
      container,
      i18n,
      router,
      store
    } = bootedApp

    if (WindowService.isDefined && process.env.VUE_APP_ENV !== 'local') {
      initSentry()
    }

    return new Vue({
      configuration,
      container,
      i18n,
      render: h => h(App),
      router,
      store
    })
  }).catch(error => {
    throw new Error(error.message)
  })
}
