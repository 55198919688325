
/**
 * Helper function that returns cookie value by a given cookie name.
 * It returns 'undefined' if cookies does not exit
 *
 * @param name
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const getCookie = (name: string): string | undefined => {
  return document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2)
}
