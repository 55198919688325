
import { SearchConfig } from '@/shared/core/src/front/shared/services/search'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const SEARCH_CONFIG = 'search'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const searchConfig: SearchConfig = {
  useSyneriseApi: true
}
