// Copyright © 2021 Move Closer

import { ImageData, IRelatedService } from '../../contexts'
import { logger } from '../../support'

import { resolveImage } from '../resolvers'

import {
  AllowedContainerColumnGap,
  ContainerBackground,
  ContainerContent,
  ContainerContentInput
} from './Container.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export async function resolveContainerContent (
  input: ContainerContentInput,
  relatedService: IRelatedService
): Promise<ContainerContent> {
  const { background, color, gridGap, margin, padding, ..._input } = input

  let image: ImageData | undefined
  if (background.image) {
    try {
      image = await resolveImage(background.image, relatedService)
    } catch (e) {
      logger(e, 'warn')
    }
  }

  const resultBackground: Partial<ContainerBackground> = {
    ...((input.background.color && input.background.color !== 'transparent') ? { color: input.background.color } : {}),
    ...(input.background.imagePosition ? { imagePosition: input.background.imagePosition } : {}),
    ...(input.background.variantColor ? { variantColor: input.background.variantColor } : {}),
    ...(image ? { image } : {})
  }

  const isEmptyBackground = Object.keys(background).length === 0
  const isDefaultGridGap = gridGap && gridGap.row === AllowedContainerColumnGap.None &&
    gridGap.column === AllowedContainerColumnGap.None
  const isEmptyMargin = margin && !margin.top && !margin.bottom
  const isEmptyPadding = padding && !padding.top && !padding.bottom

  return {
    ..._input,
    ...(color ? { color } : {}),
    ...(isEmptyBackground ? {} : { background: resultBackground }),
    ...(isDefaultGridGap ? {} : { gridGap }),
    ...(isEmptyMargin ? {} : { margin }),
    ...(isEmptyPadding ? {} : { padding })
  }
}
