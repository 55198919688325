// Copyright © 2021 Move Closer

import { Context } from '@core'
import { UVuePlugin } from '@contract/uvue-plugin'
import { validateContext } from '@support/validate-context'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
const ValidateSetupPlugin: UVuePlugin = {
  async created (context: Context) {
    validateContext(context)
  }
}

export default ValidateSetupPlugin
