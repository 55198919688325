import { MaskRules } from './contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 *
 * @see rules: https://robinherbots.github.io/Inputmask/#/documentation
 */
export const inputValidationMaskRules: MaskRules = {
  PL: {
    postalCode: '99-999',
    phoneDB: '+48999999999',
    phone: '+48 999 999 999'
  }
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum InputField {
  // Define fields
  // ...
  PostalCode = 'postalCode',
  Phone = 'phone',
  PhoneDB = 'phoneDB'
}
