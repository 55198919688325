
import { Component, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { COMPONENTS_STRUCTURE_CONFIG } from '../../../support'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component
export class ComponentOptions extends Vue {
  protected getComponentOptions<C = AnyObject> (defaultConfig?: AnyObject): C {
    const configCandidate: AnyObject | null | undefined = this.$configuration?.byKey(
      `${COMPONENTS_STRUCTURE_CONFIG}.options.${this.$options.name}`,
      false,
      defaultConfig
    )

    if (!configCandidate) {
      return defaultConfig as C
    }

    return {
      ...defaultConfig,
      ...configCandidate
    } as C
  }
}

export default ComponentOptions
