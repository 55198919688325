
import { CartModuleConfig } from '@/shared/core/src/front/checkout/services/cart'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CART_CONFIG_KEY = 'cart'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const cartConfig: CartModuleConfig = {
  hasBundledProducts: true
}
