import { AppModule, AuthServiceType, Container, Interfaces, Module } from '@movecloser/front-core'

import { GraphQlConnectorType, IGraphQL } from '../../contexts'

import { AuthGraphQLMiddlewareType } from '../auth/services'

import { IWishList, WishListService, WishListServiceType } from './services/wishlist'
import { wishListQueries } from './config/queries'

import { routesFactory as routes } from './routes'
import { storeFactory as state } from './store'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@AppModule({
  name: 'wishlist',
  providers: () => (bind: Interfaces.Bind) => {
    bind<IWishList>(WishListServiceType).toDynamicValue((context) => {
      return new WishListService(context.container.get(GraphQlConnectorType), context.container.get(AuthServiceType))
    }).inSingletonScope()
  },
  boot (container: Container) {
    const graph = container.get<IGraphQL>(GraphQlConnectorType)
    graph.useMiddlewares(container.get(AuthGraphQLMiddlewareType))
    graph.useQueries(wishListQueries)
  },
  routes,
  state
})

export class WishListModule extends Module {}
