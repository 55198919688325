// Copyright © 2021 Move Closer

import { Locale, LocaleMessages } from 'vue-i18n'
import merge from 'lodash/merge'

import { FALLBACK_LOCALE, localeHtmlLangRegistry } from '@/bootstrap/i18n'
import { HtmlLang } from '@contract/i18n'

/**
 * Resolves the applicable `HtmlLang` for the passed-in `Locale`.
 *
 * @param locale - Current locale (language) of the app.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const getHtmlLangAttrValue = (locale: Locale): HtmlLang => {
  if (
    typeof locale !== 'string' ||
    !Object.prototype.hasOwnProperty.call(localeHtmlLangRegistry, locale) ||
    typeof localeHtmlLangRegistry[locale] !== 'string'
  ) {
    return getHtmlLangAttrValue(FALLBACK_LOCALE)
  }

  return localeHtmlLangRegistry[locale]
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author VueI18n
 */
export const loadLocaleMessages = async (): Promise<LocaleMessages> => {
  const localeMessages: LocaleMessages = {}

  merge(localeMessages, {
    pl: (await import(/* webpackChunkName: "i18n" */ '@/shared/core/src/locales/pl')).frontAppPL
  }, {
    pl: (await import(/* webpackChunkName: "i18n" */ '@/locales/pl')).frontAppPL
  })

  return localeMessages
}
