import { render, staticRenderFns } from "./StaticView.vue?vue&type=template&id=935aa830&"
import script from "./StaticView.vue?vue&type=script&lang=ts&"
export * from "./StaticView.vue?vue&type=script&lang=ts&"
import style0 from "./StaticView.vue?vue&type=style&index=0&id=935aa830&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports