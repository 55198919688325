























































import { AnyObject } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { Inject, ISiteService, logger, SiteServiceType } from '@core'

/**
 * Component capable of rendering the error details
 * using the `$errorHandler` property from the _UVue_ package.
 */
@Component<ErrorDetails>({
  name: 'ErrorDetails',
  metaInfo () {
    return { title: this.$t('_.error') as string }
  },

  created (): void {
    this.logToConsole()
  }
})
export class ErrorDetails extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  /**
   * Error code.
   */
  public get errorCode (): number | undefined {
    if (typeof this.$errorHandler.statusCode !== 'number') {
      return
    }

    return this.$errorHandler.statusCode
  }

  /**
   * Human-readable description of the error occurred.
   */
  public get errorMessage (): string {
    const translations = this.$t('components.error-details.codes') as AnyObject

    if (
      typeof this.errorCode !== 'number' ||
      !Object.prototype.hasOwnProperty.call(translations, this.errorCode)
    ) {
      return translations.default
    }

    return translations[this.errorCode]
  }

  public get homePage (): string {
    return this.siteService.getActiveSite().address
  }

  public get isPageNotFound (): boolean {
    return this.errorCode === 404
  }

  /**
   * Determines whether the debug details should be shown.
   */
  public get showDebugDetails (): boolean {
    return process.env.VUE_APP_DEBUG_CONSOLE === 'true'
  }

  /**
   * Logs the error details to the browser's/server's console.
   */
  private logToConsole (): void {
    logger(
      ['this.$errorHandler.statusCode:', this.$errorHandler.statusCode],
      'error'
    )
    logger(
      ['this.$errorHandler.error.message:', this.$errorHandler.error?.message],
      'error'
    )
    logger(
      ['this.$errorHandler.error.stack:', this.$errorHandler.error?.stack],
      'error'
    )
  }
}

export default ErrorDetails
